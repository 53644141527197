<template>
  <div class="brand-list-page">
    <en-table-layout
      :tableData="tableData.data"
      :loading="loading"
      :selection-change="handleSelectionChange"
    >
      <template slot="toolbar">
        <div class="col-auto toolbar-title"></div>
        <el-form-item
          label="品牌名称"
          class="col-auto"
        >
          <el-input
            size="small"
            v-model="params.name"
            placeholder="请输入品牌名称"
            clearable
          ></el-input>
        </el-form-item>

        <el-button
          @click="searchEvent"
          size="small"
          type="primary"
        >
          搜索
        </el-button>

        <div class="col"></div>

        <div class="col-auto ml-2">
          <el-button
            size="small"
            type="primary"
            @click="handleAddBrand"
          >
            添加品牌
          </el-button>
        </div>
      </template>

      <template slot="table-columns">
        <!--<el-table-column type="selection" width="100"/>-->
        <!--品牌图片-->
        <el-table-column
          label="LOGO"
          width="120"
          class-name="goods-cover-wrapper"
        >
          <template slot-scope="scope">
            <el-popover
              placement="right"
              trigger="hover"
            >
              <img
                :src="scope.row.logo"
                alt=""
                width="300"
              >
              <img
                :src="scope.row.logo"
                alt=""
                height="50"
                slot="reference"
              />
            </el-popover>
          </template>
        </el-table-column>
        <!--品牌名称-->
        <el-table-column
          prop="name"
          label="品牌名称"
        />
        <!--操作-->
        <el-table-column
          label="操作"
          width="150"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="handleEditBrand(scope.$index, scope.row)"
            >修改</el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDeleteBrand(scope.$index, scope.row)"
            >删除</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-size="tableData.page_size"
        :total="tableData.data_total"
        :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout"
        background
      >
      </el-pagination>
    </en-table-layout>

    <el-dialog-x-dialog
      :title="brandForm.brand_id ? '编辑品牌' : '添加品牌'"
      :visible.sync="dialogBrandVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="500px"
    >
      <el-form
        :model="brandForm"
        :rules="brandRules"
        ref="brandForm"
        label-width="100px"
      >
        <!--品牌名称-->
        <el-form-item
          label="品牌名称"
          prop="name"
        >
          <el-input v-model="brandForm.name"></el-input>
        </el-form-item>
        <el-form-item
          label="品牌图片"
          prop="logo"
        >
          <el-upload
            :action="MixinUploadApi"
            list-type="picture"
            :on-success="handleSuccess"
            :on-remove="handleRemove"
            :file-list="fileList"
            :multiple="false"
            :limit="1"
          >
            <el-button
              size="small"
              type="primary"
            >点击上传</el-button>
            <span
              slot="tip"
              class="el-upload__tip"
            >&nbsp;建议上传jpg/png文件，且不超过1MB</span>
          </el-upload>
        </el-form-item>
        <el-form-item style="text-align:right;">
          <el-button
            @click="dialogBrandVisible = false"
            size="small"
          >取 消</el-button>
          <el-button
            type="primary"
            @click="submitBrandForm('brandForm')"
            size="small"
          >确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog-x-dialog>
  </div>
</template>

<script>
import * as API_brand from "@/api/brand";
import EnTableLayout from "../../../../ui-components/TableLayout/src/main";

export default {
  name: "brandList",
  components: { EnTableLayout },
  data() {
    var validateLogo = (rule, value, callback) => {
      if (!this.brandForm.logo) {
        callback(new Error("请上传品牌图片"));
      } else {
        callback();
      }
    };
    return {
      /** 列表loading状态 */
      loading: false,
      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
      },
      /** 列表数据 */
      tableData: {},
      /** 被选数据 */
      selectedData: [],

      // 添加、修改品牌 dialog
      dialogBrandVisible: false,

      /** 添加、修改品牌 表单 */
      brandForm: {},
      /** 添加、修改品牌 表单规则 */
      brandRules: {
        name: [
          this.MixinRequired("请输入品牌名称！"),
          {
            min: 1,
            max: 20,
            message: "长度在 1 到 20 个字符",
            trigger: "blur",
          },
        ],
        logo: [
          {
            required: true,
            validator: validateLogo,
            trigger: "change",
          },
        ],
      },
      fileList: [],
    };
  },
  mounted() {
    this.GET_BrandList();
  },
  methods: {
    handleRemove() {
      this.fileList = [];
      this.brandForm.logo = "";
    },
    handleSuccess(res) {
      this.brandForm.logo = res.url;
      this.fileList = [{ name: "brand_image", url: res.url }];
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_BrandList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_BrandList();
    },

    /** 当选择项发生变化 */
    handleSelectionChange(val) {
      this.selectedData = val.map((item) => item.brand_id);
    },

    /** 搜索事件触发 */
    searchEvent() {
      this.params.page_no = 1;
      if (!this.params.name) delete this.params.name;
      this.GET_BrandList();
    },

    /** 修改品牌操作 */
    handleEditBrand(index, row) {
      this.brandForm.logo = row.logo;
      this.fileList = [{ name: "brand_image", url: row.logo }];
      this.brandForm = this.MixinClone(row);
      this.dialogBrandVisible = true;
    },
    /** 删除品牌操作 */
    handleDeleteBrand(index, row) {
      this.$confirm("确定要删除这个品牌吗？", "提示", { type: "warning" })
        .then(() => {
          this.DELETE_Brand(row.brand_id);
        })
        .catch(() => {});
    },
    /** 删除选中品牌操作 */
    deleteTheBrand() {
      if (this.selectedData.length < 1) {
        this.$message.error("您未选中任何品牌！");
      } else {
        this.$confirm("确定要删除这些品牌吗？", "提示", { type: "warning" })
          .then(() => {
            this.DELETE_Brand(this.selectedData);
          })
          .catch(() => {});
      }
    },

    /** 添加品牌触发事件 */
    handleAddBrand() {
      this.brandForm = {};
      this.fileList = [];
      this.dialogBrandVisible = true;
    },

    /** 添加、修改品牌 表单提交 */
    submitBrandForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const { brand_id } = this.brandForm;
          if (!brand_id) {
            API_brand.addBrand(this.brandForm).then((response) => {
              this.dialogBrandVisible = false;
              this.$message.success("添加成功！");
              this.$refs[formName].resetFields();
              this.GET_BrandList();
            });
          } else {
            API_brand.editBrand(brand_id, this.brandForm).then((response) => {
              this.$message.success("保存成功！");
              this.dialogBrandVisible = false;
              this.$refs[formName].resetFields();
              this.MixinSetTableData(
                this.tableData,
                "brand_id",
                brand_id,
                response
              );
            });
          }
        } else {
          this.$message.error("表单填写有误，请检查！");
          return false;
        }
      });
    },
    /** 获取品牌列表 */
    GET_BrandList() {
      this.loading = true;
      API_brand.getBrandList(this.params)
        .then((response) => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    /** 删除品牌 */
    DELETE_Brand(ids) {
      API_brand.deleteBrand(ids).then(() => {
        this.$message.success("删除成功！");
        this.GET_BrandList();
      });
    },
  },
};
</script>

<style lang="scss">
.brand-list-page {
  .goods-cover-wrapper {
    img {
      width: 120px;
    }
  }
}
</style>
